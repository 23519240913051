import React from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import * as moment from 'moment';

import { Link } from 'react-router-dom';

export class InBondMessages extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "id",
                TrOnlyClassName: 'tsc',
                text: "id",
                className: "tsc",
                align: "left"
            },
            {
                key: "text",
                TrOnlyClassName: 'tsc',
                text: "Text",
                className: "tsc",
                align: "left"
            },
            {
                key: "platform",
                TrOnlyClassName: 'tsc',
                text: "Platform",
                className: "tsc",
                align: "left"
            },
            {
                key: "mbalance",
                TrOnlyClassName: 'tsc',
                text: "Processed",
                className: "tsc",
                align: "left"
            },
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Time",
                className: "tsc",
                align: "left"
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: false,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }
    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "") => {
            this.setState({
                isLoading: true,
            })
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": TOKEN
                }
            };
            let url = baseURL + "inbound?mobile=" + localStorage.getItem("phone") + '&' + queryString
            console.log("query", url);
            console.log("token", TOKEN)
            axios.all([
                axios.get(url, axiosConfig),
            ]).then(axios.spread((branchResponse) => {
                this.setState({
                    admins: branchResponse.data,
                    isLoading: false,
                }, function () {

                    var data = [];
                    console.log("BUG", this.state.admins)

                    for (let i = 0; i < this.state.admins.length; i++) {
                        this.setState({
                            total: this.state.admins[0].total_page
                        })
                        let mail;
                        let mbalance;

                        if (this.state.admins[i].email == null) {
                            mail = { mail: "Not provided" };
                        } else {
                            mail = { mail: this.state.admins[i].email };
                        }

                        if (this.state.admins[i].processed === true) {
                            mbalance = { mbalance: "true" };
                        } else {
                            mbalance = { mbalance: this.state.admins[i].processed };
                        }
                        let date = { dates: moment.utc(this.state.admins[i].time).format("DD-MMM-yyyy HH:mm:ss") };

                        data.push(Object.assign(mail, date, mbalance, this.state.admins[i]));
                        this.setState({
                            data: data
                        })
                        console.log("bugs", data);
                    }
                }
                );
            }))
       

    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    render() {
        return (
            <div  >
                < >
                    <Col md={12}>
                        <h4>Customer Messages Sent</h4><br />

                    </Col>
                    < Col md={12} lg={12} >
                        < Card >
                            <CardBody >
                                <div className="panel-body" >
                                    <div className="row">

                                        <div className="col-md-12">
                                            <Link to="/userdetail">
                                                <Button className="pull-right"
                                                    color="primary" outline>Go Back</Button><br /> <br /><br />
                                            </Link>
                                        </div>
                                    </div>
                                    {this.state.isShowError ?
                                        <div className="alert alert-success" > {this.state.statusMessage}
                                        </div> : null
                                    }
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        dynamic={true}
                                        id="tsc"
                                        loading={this.state.isLoading}
                                        total_record={this.state.total}
                                        onChange={this.tableChangeHandler} /> </div>
                            </CardBody>

                        </Card>
                    </Col>
                </>
            </div>

        )
    }
}