export const baseURL = "https://portalapi.pesapot.co.ke/";

export const baseURL_2 = "https://portalapi.pesapot.co.ke/";

// export const baseURL = "http://localhost:3567/";

// // export const baseURL = "https://luckybox.ng:1220/";
// export const baseURL_2 = "https://luckybox.ng:3886/";


// Warning std versions prefixed with 'v' will be deprecated on October 1st 2020. Please change your import to  (at https://deno.land/std@v0.63.0/fmt/colors.ts)