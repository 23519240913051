import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BetPerHour } from '../../../Bets/bets';
import { JackPot } from '../../../Bets/jackpot';
import { OtherWinners } from '../../../Bets/others';
import { AverageBet } from '../../../Bets/others copy';
import { BetsPerDay } from '../../../Bets/betsperday';
import CSV from '../../../CSV';
import { editSMS } from '../../../Blasts/editBlast';
import { JackPotCount } from '../../../Bets/jackpotcount';
import { BetsType } from '../../../Bets/betstype';
import FileUploadDefault from '../../../CSV/components/FileUploadDefault';
import { BlastCountPlayed } from '../../../Blasts/countPlayed';
import { activeDepositors } from '../../../Blasts/activeDepositors';
import { sendSMSDateCreated } from '../../../Blasts/sendSMSDateCreated';
import Lucky4 from '../../../CSV/components/Lucky4';
import PesaCode from '../../../CSV/components/PesaCode';

export default () => (
  <Switch>
    <Route path="/betsperday" component={BetsPerDay} />
    <Route path="/betsperhour" component={BetPerHour} />
    <Route path="/jackpot" component={JackPot} />
    <Route path="/otherwinners" component={OtherWinners} />
    <Route path="/averagebets" component={AverageBet} />

    <Route path="/reconciledeposits" component={CSV} />

    <Route path="/jackpotcount" component={JackPotCount} />

    <Route path="/betstype" component={BetsType} />

    <Route path="/editmessage" component={editSMS} />

    <Route path="/luckybox" component={FileUploadDefault} />
    <Route path="/Lucky4" component={Lucky4} />
    <Route path="/PesaCode" component={PesaCode} />


    <Route path="/blastcountplayed" component={BlastCountPlayed} />

    <Route path="/active_blasts" component={activeDepositors} />

    <Route path="/sendmonthly" component={sendSMSDateCreated} />

    {/* <Route path="/airtime" component={FileUpload} /> */}

    {/* <Route/> */}
  </Switch>
);
