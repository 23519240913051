import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import ReactLoading from 'react-loading';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL, baseURL_2 } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import Pagination from '../../shared/components/pagination/Pagination';
import DataPaginationTable from '../../shared/components/table/DataPaginationTable';
import * as moment from 'moment';
import { confirm } from "../../shared/components/confirm";
import Alert from '../../shared/components/Alert';
import { Redirect } from 'react-router';

export class QueeMessages extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [

            {
                key: "message",
                TrOnlyClassName: 'tsc',
                text: "Message",
                className: "tsc",
                align: "left"
            },

            {
                key: "dispatch_time",
                TrOnlyClassName: 'cell',
                text: "Dispatch Time",
                className: "cell",
                align: "left"
            },


            {
                key: "date_created",
                TrOnlyClassName: 'cell',
                text: "Date Created",
                className: "cell",
                align: "left"
            },
            {
                key: "verified",
                TrOnlyClassName: 'tsc',
                text: "Verified",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.verified == true ?
                                <span span class="badge-success" style={{ borderRadius: "5px", padding: "2px", }}>
                                    True
                                </span>
                                : null}
                            {record.verified == false ?
                                <span class="badge-false" style={{ borderRadius: "5px", padding: "2px" }}>
                                    False
                                </span>
                                : null}
                        </Fragment >
                    );
                }
            },
            {
                key: "verified",
                TrOnlyClassName: 'tsc',
                text: "Queued",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center"  >
                            {record.queued == true ?
                                <span span class="badge-success" style={{ borderRadius: "5px", padding: "2px", }}>
                                    True
                                </span>
                                : null}
                            {record.queued == false ?
                                <span class="badge-danger" style={{ borderRadius: "5px", padding: "2px" }}>
                                    False
                                </span>
                                : null}
                        </Fragment >
                    );
                }
            },
            {

                key: "blastcount",
                text: "Blast Count",
                TrOnlyClassName: 'tsc',
                className: "tsc",
                align: "left",
            },
            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            {record.queued == false ?
                                <>
                                    <button className="btn btn-primary btn-sm"
                                        title="Eith details"
                                        style={
                                            { marginRight: '10px' }}
                                        onClick={
                                            () => { this.handleOnClickEdit(record) }}
                                    >
                                        Edit

                            </button>
                                    <button className="btn btn-danger btn-sm"
                                        title="Deactive"
                                        style={
                                            { marginRight: '10px' }}
                                        onClick={
                                            () => { this.handleOnClick(record) }} > Delete
                            </button>
                                </>
                                : null}


                            {record.verified == false ?
                                <>
                                    <button className="btn btn-primary btn-sm"
                                        title="Eith details"
                                        style={
                                            { marginRight: '10px' }}
                                        onClick={
                                            () => { this.handleOnClick2(record) }} >
                                        Approve
                            </button>
                                </>
                                : null}



                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            key_column: "tsc",
            button: {
                csv: true,
            },
            show_filter: false,
            show_pagination: true,
            pagination: 'basic',
            filename: "Luckybox blast",
            page_size: 50,
            show_length_menu: true,
            length_menu: [50, 100, 500, 1000, 10000],
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search Blacklist Number",
                no_data_text: "No message in the queue"
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            editstate: false,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }


    onApprove(record) {
        var sha512 = require('js-sha512');

        let signature = sha512('P@s5w0rd_5@lt' + record.id)

        let formData = {
            "blast_message_id": record.id,
        }
        axios.post(baseURL_2 + "verify_scheduled_message", formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'signature': signature
            },
        }).then(response => {
            console.log("true", response.data)

            alert("Successfully verified")
            if (response.data.code === "0000") {
                this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });
            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false }, function () {
                    // window.setTimeout(() => {
                    //     this.getData();
                    //     this.setState({ isShowError: false, showError: false })
                    // }, 2000);
                });
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }


    onDeactivate(record) {

        var sha512 = require('js-sha512');
        let signature = sha512('P@s5w0rd_5@lt' + record.id)

        let formData = {
            "blast_message_id": record.id
        }
        axios.post(baseURL_2 + "delete_scheduled_message", formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'signature': signature
            },
        }).then(response => {
            console.log("true", response.data)

            alert("Successfully Deleted")

            if (response.data.code === "0000") {
                this.setState({ statusMessage: response.data.message, isShowError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false })
                    }, 2000);
                });

            } else {
                this.setState({ statusMessage: response.data.message, isShowError: false, showError: true, isLoading: false }, function () {
                    window.setTimeout(() => {
                        this.getData();
                        this.setState({ isShowError: false, showError: false })
                    }, 2000);
                });
            }
        }, error => {
            this.setState({ statusMessage: JSON.stringify(error), isShowError: true, isLoading: false });
        });
    }

    async handleOnClick2(record) {
        console.log("hoge!");
        if (await confirm("Verify this message?")) {
            this.onApprove(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }

    async handleOnClick(record) {
        console.log("hoge!");
        if (await confirm("Delete this message?")) {
            this.onDeactivate(record)
        } else {
            this.setState({
                message: "No!"
            });
        }
    }


    async handleOnClickEdit(record) {
        console.log("hoge!");

        localStorage.setItem('message_sent', record.message)
        localStorage.setItem('message_id', record.id)
        localStorage.setItem('dispatch_time', record.dispatch_time)
        this.setState({ editstate: true })
    }


    componentDidMount() {



        let queryString = "filter_value=&page_number=1&page_size=50&sort_order=false"

        this.getData(queryString);


    }
    getData = (queryString ) => {
        
        this.setState({
            isLoading: true,
        })


        let url = "https://luckybox.ng:3886/get_scheduled_messages?" + queryString
        console.log("query", url);
        var sha512 = require('js-sha512');

        let signature = sha512('P@s5w0rd_5@lt')

        axios.all([
            axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    "signature": signature,
                }
            })]).then(axios.spread((branchResponse) => {
                
                this.setState({
                    admins: branchResponse.data.blast_messages,
                    mtotal: branchResponse.data.blast_count,
                    isLoading: false,
                },
                    function () {
                        var data = [];

                        console.log("BUG", this.state.admins)
                        if (this.state.admins.length > 0) {
                            for (let i = 0; i < this.state.admins.length; i++) {
                                let mqueue = { mqueue: this.state.admins[i].queued.toString() };
                                let blastcount = { blastcount: this.state.admins[i].blast_count.toString() };
                                let mverified = { mverified: this.state.admins[i].verified.toString() };
                                data.push(Object.assign(mqueue, blastcount, mverified, this.state.admins[i]));
                                this.setState({
                                    data: data,
                                    isLoading: false,
                                })
                                console.log("bugs", i);
                            }
                        } else {
                            this.setState({
                                data: data,
                                isLoading: false,
                            })
                            localStorage.setItem("balstmessage", "")
                            console.log("bugs", data);
                        }
                    }
                );
            }))

    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }

    render() {
        const { isLoading, editstate } = this.state;
        // console.log("Load", isLoading);
        return (
            <div style={{ marginTop: '-25px' }} >
                < >
                    { editstate == true ?
                        <Redirect to="editmessage" />
                        : null}
                    < Col md={12} lg={12} >
                        < Card >
                            <CardBody >
                                <div className="panel-body" >
                                    <h4>Scheduled Messages</h4><br />

                                    <div>
                                    </div>

                                    {this.state.showError ? <div >

                                        <Alert color="danger" className="alert--colored" icon>
                                            <p><span className="bold-text">{this.state.statusMessage}</span>
                                            </p>
                                        </Alert>

                                    </div> : null}

                                    {this.state.isShowError ? (
                                        <div>

                                            <Alert color="success" className="alert--colored" icon>
                                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                                </p>
                                            </Alert>

                                        </div>

                                    ) : null}<br></br>

                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        dynamic={true}
                                        id="tsc"
                                        loading={this.state.isLoading}
                                        total_record={this.state.mtotal}
                                        onChange={this.tableChangeHandler} />
                                </div>
                            </CardBody>

                        </Card>
                    </Col>
                </>
            </div>

        )
    }
}