import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import ReactLoading from 'react-loading';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import Pagination from '../../shared/components/pagination/Pagination';
import DataPaginationTable from '../../shared/components/table/DataPaginationTable';
import * as moment from 'moment';

export class QueeMessagesMessage extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [{
            key: "Originator",
            text: "Originator",
            TrOnlyClassName: 'tsc',
            className: "tsc",
            align: "left",
        },
        {
            key: "Destination",
            text: "Name",
            TrOnlyClassName: 'tsc',
            className: "tsc",
            align: "left",
        },
        {
            key: "Message",
            TrOnlyClassName: 'cell',
            text: "Message",
            className: "cell",
            align: "left"
        },
        {
            key: "SMSCID",
            TrOnlyClassName: 'tsc',
            text: "SMSCID",
            className: "tsc",
            align: "left"
        }
        ];
        // deno-lint-ignore no-dupe-keys
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            button: {
                excel: false,
                // extra: true,
                // print: false
            },
            show_filter: false,
            show_pagination: true,
            pagination: 'basic',
            page_size: 500,
            show_length_menu: true,
            length_menu: [10, 20, 30, 100, 500, 1000, 10000],
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search Blacklist Number",
                no_data_text: "No message in the queue"
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }

    componentDidMount() {
        this.getData();
    }
    getData = (queryString = "") => {
        this.setState({
            isLoading: true,
        })
        let url = baseURL + "sms?" + queryString
        console.log("query", url);

        axios.all([
            axios.get(url)]).then(axios.spread((branchResponse) => {
                this.setState({
                    admins: branchResponse.data.data,
                    total: branchResponse.data.total,
                    isLoading: false,
                },
                    function () {
                        var data = [];

                        console.log("BUG", this.state.admins)
                        if (this.state.admins.length > 0) {
                            for (let i = 0; i < this.state.admins.length; i++) {
                                let date = { dates: moment(this.state.admins[i].MessageTimeStamp).format('DD MMM, YYYY hh:mm:ss') };
                                data.push(Object.assign(date, this.state.admins[i]));
                                this.setState({
                                    data: data,
                                    isLoading: false,
                                })
                                console.log("bugs", i);
                            }
                        } else {
                            this.setState({
                                data: data,
                                isLoading: false,
                            })
                            localStorage.setItem("balstmessage", "")
                            console.log("bugs", data);
                        }
                    }
                );
            }))

    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }

    render() {
        const { isLoading } = this.state;
        // console.log("Load", isLoading);
        return (
            <div style={{ marginTop: '-25px' }} >
                < >
                    <div >
                        <h4>Queue Messages</h4><br />
                    </div>
                    < Col md={12} lg={12} >
                        < Card >
                            <CardBody >
                                <div className="panel-body" >
                                    <div>
                                        <h4> Total Messages: <b>{localStorage.getItem("balstmessage")}</b>  </h4>
                                        <br /><br /><br />
                                    </div>

                                    {this.state.isShowError ?
                                        <div className="alert alert-success" > {this.state.statusMessage}
                                        </div> : null
                                    }
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        dynamic={true}
                                        id="tsc"
                                        loading={this.state.isLoading}
                                        total_record={this.state.total}
                                        onChange={this.tableChangeHandler} />
                                </div>
                            </CardBody>

                        </Card>
                    </Col>
                </>
            </div>

        )
    }
}