import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BetSummary } from '../../../Reports/summary';
import { EOD } from '../../../Reports/eod';
import { MTNAnalysis } from '../../../Reports/mtnanalysis';
import { GamingIncome } from '../../../Reports/gameincome';
import { RevenueChannel } from '../../../Reports/revenuechannel';
import { WithdrawalSummary } from '../../../Reports/withdawals';
import { CustomerSubscription } from '../../../Reports/customersubscription';
import { TimePlayed } from '../../../Reports/timeplayed';
import { DepositAttempts } from '../../../Reports/depositsattempts';
import { NewlyRecruitedCustomers } from '../../../Reports/newlyrecruitedcustomers';
import { ActiveCustomers } from '../../../Reports/activecustomers';
import { InActiveCustomers } from '../../../Reports/inactivecustomers';
import { InActiveCustomersMonth } from '../../../Reports/inactivecustomersovermonth';
import { CustomerFeedBacks } from '../../../Reports/customerfeedbacks';
import { GamingIncomeMonthly } from '../../../Reports/gameincomemonthly';
import { RevenueChannelMonthly } from '../../../Reports/revenuechannelmonthly';
import { TimePlayedMonthly } from '../../../Reports/timeplayedmothly';
import { MTNAnalysisMonthly } from '../../../Reports/mtnanalysismonthly';
import { DepositAttemptsMonthly } from '../../../Reports/depositsattemptsmonthly';
import { WithdrawalSummaryMonthly } from '../../../Reports/withdawalsMonthly';
import { DailyDepositsMosntly } from '../../../Reports/dailydeposit';
import { PauStackMosntly } from '../../../Reports/paystacksmonthly';
import { JackPotCountMonthly } from '../../../Reports/jackpotcountmonthly';
import { BetsPerMonthly } from '../../../Bets/betsmonthly';
import { PlayedCustomers } from '../../../Reports/playedcustomers';
import { PlayedCustomersYesterday } from '../../../Reports/playedcustomersyestday';
import { PlayedCustomersFourTImes } from '../../../Reports/playedcustomersfourtimes';
import { PlayedCustomersThanFourMonth } from '../../../Reports/playedcustomersmorethanfourmonth';
import { DepositAttemptsCustomer } from '../../../Reports/customerdepositattemps';
import { NetCashFlow } from '../../../Reports/netcashflow';
// import { sendSMSDateCreated } from '../../../Blasts/sendSMSDateCreated';
import { CustomerFullInfo } from '../../../Reports/customerfullinfo';
import { SocialMedia } from '../../../Reports/socialmediia';
import { CohortAnalysis } from '../../../Reports/cohortSend';
import { CustomerIncomingMessage } from '../../../Reports/CustomerincomingMessages';
import { NewlyRecruitedDeposits } from '../../../Reports/newlyrecruitedvsdeposits';
import { OutCustomerFeedBacks } from '../../../Reports/outcustomerfeedbacks';
import { BetsPerAfterCall } from '../../../Reports/betsperdayaftercall';
import LoginAudit from '../../../Reports/LoginAudit';
import CustomerAttandance from '../../../Reports/CustomerAttandance';
import { QueeMessagesMessage } from '../../../Blasts/bulksmsMessage';
import { DepositPerHour } from '../../../Finance/depositPerHour';
import { MO } from '../../../Reports/mo';

export default () => (
  <Switch>
    <Route path="/summary" component={BetSummary} />
    <Route path="/eod" component={EOD} />

    <Route path="/mtnanaylsis" component={MTNAnalysis} />
    <Route path="/gamingincome" component={GamingIncome} />
    <Route path="/revenuechannel" component={RevenueChannel} />
    <Route path="/withdrawalsummary" component={WithdrawalSummary} />

    <Route path="/customersaftercall" component={BetsPerAfterCall} />


    <Route path="/outgoingcustomerfeedbacks" component={OutCustomerFeedBacks} />

    <Route path="/customersubscription" component={CustomerSubscription} />

    <Route path="/timesplayed" component={TimePlayed} />

    <Route path="/uniquedepositor" component={DepositAttempts} />


    <Route path="/newlycustomer" component={NewlyRecruitedCustomers} />

    <Route path="/activecustomers" component={ActiveCustomers} />

    <Route path="/inactivecustomers" component={InActiveCustomers} />

    <Route path="/inactivecustomersmonth" component={InActiveCustomersMonth} />

    {/* <Route path="/calledstatus" component={CustomerCalledStatus} /> */}

    <Route path="/customerfeedback" component={CustomerFeedBacks} />


    <Route path="/incomemonthly" component={GamingIncomeMonthly} />

    <Route path="/bulksmsmessage" component={QueeMessagesMessage} />

    

    <Route path="/revenuemonthly" component={RevenueChannelMonthly} />

    <Route path="/deposit_per_hour" component={DepositPerHour} />

    <Route path="/audit" component={LoginAudit} />
    <Route path="/audit_performance" component={CustomerAttandance} />

    <Route path="/timeplayedmonthly" component={TimePlayedMonthly} />

    <Route path="/mtnepositmonthly" component={MTNAnalysisMonthly} />

    <Route path="/uniquedepositormonthly" component={DepositAttemptsMonthly} />

    <Route path="/depositsmonthly" component={DailyDepositsMosntly} />

    <Route path="/paystackmonthly" component={PauStackMosntly} />

    <Route path="/jackpotmonthly" component={JackPotCountMonthly} />

    <Route path="/betmonthly" component={BetsPerMonthly} />

    <Route path="/threedayscustomers" component={PlayedCustomers} />

    <Route path="/yesterdaycustomers" component={PlayedCustomersYesterday} />

    <Route path="/playforthtimescustomers" component={PlayedCustomersFourTImes} />

    <Route path="/playoveramonth" component={PlayedCustomersThanFourMonth} />

    <Route path="/depositattemptscustomer" component={DepositAttemptsCustomer} />

    <Route path="/netcashflow" component={NetCashFlow} />

    {/* <Route path="/sendmonthly" component={sendSMSDateCreated} /> */}

    <Route path="/customerfullinfo" component={CustomerFullInfo} />

    <Route path="/socialmediaposts" component={SocialMedia} />

    <Route path="/cohortAnalysis" component={CohortAnalysis} />

    <Route path="/customerincomingMessages" component={CustomerIncomingMessage} />

    <Route path="/newlyrecruiteddeposits" component={NewlyRecruitedDeposits} />





    <Route path="/withdrawlsummarymonthly" component={WithdrawalSummaryMonthly} />

    <Route path="/mo_counts" component={MO} />
    {/* 
new Date(new Date().getFullYear(), new Date().getMonth(), 1)
new Date(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1)))
 */}



  </Switch>
);
