import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import { TOKEN } from '../../_helpers/token';
import Modal from 'react-modal';
import './style.css';
import { MDBCloseIcon } from "mdbreact"
import Select from 'react-select';
import '../users/style.css'



const options = [
    { value: 'Called', label: 'Called' },
    { value: 'Has Called', label: 'Has Called' },
    { value: 'Not Called', label: 'Not Called' }
];

const options_3 = [
    { value: 'Incoming call', label: 'Incoming call' },
    { value: 'Outgoing call', label: 'Outgoing Call' }
];

const options_2 = [
    { value: 'Pending', label: 'Pending' },
    { value: 'Happy', label: 'Happy' },
    { value: 'Unhappy', label: 'Unhappy' },
    { value: 'Annoyed', label: 'Annoyed' }

];

export class UserDetails extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModalCreate = this.toggleModalCreate.bind(this);
        this.onSubmitRemark = this.onSubmitRemark.bind(this);
        this.onSubmitRemarkUpdate = this.onSubmitRemarkUpdate.bind(this);

        this.onSubmitAfterCallingCustomer = this.onSubmitAfterCallingCustomer.bind(this);

        this.onSubmitAfterCustomerCalling = this.onSubmitAfterCustomerCalling.bind(this);


        this.onSubmitblacklist = this.onSubmitblacklist.bind(this);


        this.columns = [
            {
                key: "id",
                TrOnlyClassName: 'tsc',
                text: "id",
                className: "tsc",
                align: "left"
            },
            {
                key: "phone",
                TrOnlyClassName: 'tsc',
                text: "msisdn",
                className: "tsc",
                align: "left"
            },
            {
                key: "firstName",
                TrOnlyClassName: 'tsc',
                text: "firstName",
                className: "tsc",
                align: "left"
            },
            {
                key: "otherNames",
                TrOnlyClassName: 'tsc',
                text: "otherNames",
                className: "tsc",
                align: "left"
            },
            {
                key: "mbalance",
                TrOnlyClassName: 'tsc',
                text: "Balance",
                className: "tsc",
                align: "left"
            },
            {
                key: "mjoiningKeyword",
                TrOnlyClassName: 'tsc',
                text: "Join KeyWord",
                className: "tsc",
                align: "left"
            },


        ];


        this.columns3 = [
            {
                key: "JackpotWinners",
                TrOnlyClassName: 'tsc',
                text: "Jackpot Winners",
                className: "tsc",
                align: "left"
            },

            {
                key: "mtimeCreated",
                TrOnlyClassName: 'tsc',
                text: "TIme Active",
                className: "tsc",
                align: "left"
            },

            {
                key: "mactive",
                TrOnlyClassName: 'tsc',
                text: "Active",
                className: "tsc",
                align: "left"
            },
            {
                key: "dates2",
                TrOnlyClassName: 'tsc',
                text: "Last Date Active",
                className: "tsc",
                align: "left"
            },
            {
                key: "action",
                text: "Remarks",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            <button className="btn btn-primary"
                                title="Remarks"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { this.toggleModalCreate(record) }} >
                                <span className="" > Click here </span>
                            </button>

                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: "Blacklist?",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 250,
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center" >
                            <button className="btn btn-danger"
                                title="Blacklist?"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => {
                                        if (window.confirm('Are you sure you want to add this customer to blacklist?'))
                                            this.onSubmitblacklist(record)
                                    }
                                } >

                                <span className="" > Click here </span>
                            </button>

                        </Fragment>
                    );
                }
            }

        ]
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: false,
            show_pagination: false,
            pagination: 'basic',
            page_size: 10,
            show_info: false,
            show_length_menu: false,
            language: {
                loading_text: "Please be patient while data loads..."
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
            oncall: true,
            call_status: "On Call",
            remarks: "Pending",
            isOpen: false,
            isOpenModel: false,
            call_value: [
                { value: 'On Call', label: 'On Call' }],

            remark_value: [

                { value: 'Pending', label: 'Pending' }],
           
        };
    }

    toggleModalCreate = e => {
        this.setState({
            isOpen: true,
        });
    };

    closeModalUpload = e => {
        this.setState({
            isOpen: false,
        });
    };

    onSubmitblacklist(record) {


        let formData = {
            "mobile": record.phone
        }
        console.log("DATA", JSON.stringify(formData))
        // this.setState({ isLoading: true });

        axios.post(baseURL + 'createBlackList', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }).then((response) => {
            console.log("bayo", response)
            // eslint-disable-next-line
            if (response.data.success == true) {
                console.log("records", formData)
                this.setState({ isShowError: true, isLoading: false });

                alert(response.data.message)

            } else {
                alert(response.data.message)

                this.setState({ isShowError: true, isLoading: false });

            }

        })
            .catch(error => {
                console.log('bayoo', error.response)

                this.setState({ isShowError: true, isLoading: false });


                alert(error.response.data.message)

            });

    }



    onSubmitAfterCallingCustomer(e) {
        e.preventDefault();

        let formData = {
            "msisdn": localStorage.getItem('phone'),
            "message": "Thanks for your time and response to luckybox Nigeria.",
        }

        this.setState({ isLoadingCalls: true });
        axios.post(baseURL + 'smsresponse', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then((response) => {

            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, showError: false, isShowError: true });
                window.scrollTo(0, 0)
                window.setTimeout(() => {
                    this.setState({ isShowError: false, isOpenModel: false, isLoadingCalls: false });
                    alert(response.data.message)
                }, 2000);

            } else {
                this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, });
                window.setTimeout(() => {
                    this.setState({
                        isLoadingCalls: false,
                        isShowError: false,
                        statusMessage: "",
                        isOpenModel: false,
                        showError: false
                    });
                    alert(response.data.message)
                }, 2000);
            }
        }).catch((error) => {
            console.log('bayoo', error)
            this.setState({
                statusMessage: error.response === undefined ? "Check your Internet" : error.response.data.message,
                showError: true, isShowError: false
            });
            window.setTimeout(() => {
                this.setState({
                    isLoadingCalls: false,
                    isShowError: false,
                    showError: false,
                    errorShow: false
                });

                alert(this.state.statusMessage)
            }, 2000);
        })
    }

    onSubmitAfterCustomerCalling(e) {
        e.preventDefault();

        let formData = {
            "msisdn": localStorage.getItem('phone'),
            "message": "Thanks for calling luckybox Nigeria",
        }

        this.setState({ isLoadisLoadingCallsing2: true });
        axios.post(baseURL + 'smsresponse', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then((response) => {

            if (response.data.status) {
                this.setState({ statusMessage: response.data.message, showError: false, isShowError: true });
                window.scrollTo(0, 0)
                window.setTimeout(() => {
                    this.setState({ isShowError: false, isOpenModel: false, isLoadisLoadingCallsing2: false });
                    alert(response.data.message)
                }, 2000);

            } else {
                this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, });
                window.setTimeout(() => {
                    this.setState({
                        isLoadisLoadingCallsing2: false,
                        isShowError: false,
                        statusMessage: "",
                        isOpenModel: false,
                        showError: false
                    });
                    alert(response.data.message)
                }, 2000);
            }
        }).catch((error) => {
            console.log('bayoo', error)
            this.setState({
                statusMessage: error.response === undefined ? "Check your Internet" :
                    error.response.data.message, showError: true, isShowError: false
            });
            window.setTimeout(() => {
                this.setState({
                    isLoadisLoadingCallsing2: false,
                    isShowError: false,
                    showError: false,
                    errorShow: false
                });

                alert(this.state.statusMessage)
            }, 2000);
        })
    }

    componentDidMount() {


        this.setState({ isLoading: true });
        axios.get(baseURL + 'customerOneDetails/' + localStorage.getItem("phone"), {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then((response) => {
                console.log("bayo", response.data)
                this.setState({
                    admins: response.data,
                    isLoading: false
                },
                    function () {

                        axios.get(baseURL + 'getCountOutMsgs/' + localStorage.getItem("phone"), {
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                        }).then((response) => {
                            localStorage.setItem('customerMsgOut', response.data.total_page)

                        })
                        var data = [];
                        for (let i = 0; i < this.state.admins.length; i++) {
                            let mbalance;
                            let xactive;


                            let maccount;
                            let phone;
                            let mjoiningKeyword;

                            if (this.state.admins[i].JackpotWinner === 0) {
                                maccount = { JackpotWinners: "Inactive" };
                            } else {
                                maccount = { JackpotWinners: "Active" };
                            }
                            mbalance = { mbalance: this.state.admins[i].balance.toString() }
                            // let mticketRate = { mticketRate: this.state.admins[i].ticketRate.toString() }
                            // let mnetCashValue = { mnetCashValue: this.state.admins[i].netCashValue.toString() }

                            if (this.state.admins[i].msisdn === null) {
                                phone = { phone: "_" };
                            } else {
                                phone = { phone: this.state.admins[i].msisdn };
                            }

                            if (this.state.admins[i].joiningKeyword === null) {
                                mjoiningKeyword = { mjoiningKeyword: "_" };
                            } else {
                                mjoiningKeyword = { mjoiningKeyword: this.state.admins[i].joiningKeyword };
                            }

                            localStorage.setItem('customerID', this.state.admins[i].id)
                            // alert(this.state.admins[i].id)

                            // let mjackpotAmount = { mjackpotAmount: this.state.admins[i].jackpotAmount.toString() }





                            // if (this.state.admins[i].mbonusAllowed === 0) {
                            //     mbonusAllowed = { mbonusAllowed: "True" };
                            // } else {
                            //     mbonusAllowed = { mbonusAllowed:  "False"};
                            // }
                            // let mticketBundle = { mticketBundle: this.state.admins[i].ticketBundle.toString() }
                            // let mbonus = { mbonus: this.state.admins[i].bonus.toString() }

                            // let mpromoCode = { mpromoCode: this.state.admins[i].promoCode.toString() }

                            let dates = { dates2: moment(this.state.admins[i].date_last_active).format('DD MMM, YYYY HH:MM') };

                            // let bonus_dates = { bonus_dates: moment(this.state.admins[i].bonusExpiryDate).format('DD MMM, YYYY HH:MM') };


                            let mtimeCreated = { mtimeCreated: moment(this.state.admins[i].timeCreated).format('DD MMM, YYYY HH:MM') };

                            xactive = { mactive: this.state.admins[i].active.toString() };



                            data.push(Object.assign(xactive, mjoiningKeyword, maccount, phone,

                                mtimeCreated, mbalance, dates, this.state.admins[i]));
                            this.setState({
                                data: data,
                                number: this.state.admins[i].msisdn
                            })
                        }

                    }
                );
            })

        axios.get(baseURL + 'getcall/' + localStorage.getItem('phone').replaceAll("+", ""), {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then((response) => {
            // alert(JSON.stringify(response))
            if (response.data.total === 0) {
                this.setState({
                    oncall: false,
                    mstatus: response.data.data.length === 0 ? "Not Called" : response.data.data[0].status,
                    mdateStatus: response.data.data.length === 0 ? "Not Called" : response.data.data[0].date_created,

                    isLoading: false
                })
            } else {
                this.setState({
                    oncall: true,
                    mstatus: response.data.data[0].status,
                    isLoading: false
                })

                // alert(response.data.data[0].status)
            }

        })
    }

    onSubmitRemark() {
        // e.preventDefault();
        this.setState({ isLoading4: true });
        let formData = {
            mobile: localStorage.getItem('phone').replaceAll("+", ""),
            status: this.state.call_status,
            call_type: "unkwown_call",
            name: localStorage.getItem("name"),
            msisdn: localStorage.getItem("msisdnrecord"),
            remarks: this.state.remarks
        }
        this.setState({ isLoading4: true });
        axios.post(baseURL + 'remark', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then((response) => {

            if (response.data.status) {

                this.setState({ statusMessage: response.data.message, isLoading4: false, showError: false, isShowError: true });
                window.scrollTo(0, 0)
                // alert("Success");
                axios.get(baseURL + 'getcall/' + localStorage.getItem('phone').replaceAll("+", ""), {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json'
                    }
                }).then((response) => {
                    if (response.data.total === 0) {
                        this.setState({
                            oncall: false,
                            mstatus: response.data.data.length === 0 ? "Not Called" : response.data.data[0].status,
                            isLoading: false
                        })
                    } else {
                        this.setState({
                            oncall: true,
                            mstatus: response.data.data[0].status,
                            isLoading: false
                        })
                        // alert(response.data.data[0].status)
                    }
                })
            } else {
                this.setState({ statusMessage: response.data.message, isLoading4: false, showError: true, isShowError: false, });
            }
        }).catch((error) => {
            this.setState({ isLoading4: false });

            alert(JSON.stringify(error.response))
        })
    }


    onSubmitRemarkUpdate() {
        // e.preventDefault();
        this.setState({ isLoading4: true });
        let formData = {
            mobile: localStorage.getItem('phone').replaceAll("+", ""),
            status: this.state.call_status,
            call_type: this.state.call_type,
            conversation_summary: this.state.message,
            remarks: this.state.remarks
        }


        // alert(JSON.stringify(formData))
        this.setState({ isLoading4: true });
        axios.post(baseURL + 'remarkupdate', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then((response) => {
            this.closeModalUpload();
            alert("Feedback send");




            this.setState({ statusMessage: response.data.message, isLoading4: false, showError: false, isShowError: true });
            window.scrollTo(0, 0)

            axios.get(baseURL + 'getcall/' + localStorage.getItem('phone').replaceAll("+", ""), {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json'
                }
            }).then((response) => {
                if (response.data.total === 0) {


                    this.setState({
                        oncall: false,
                        mstatus: response.data.data.length === 0 ? "Not Called" : response.data.data[0].status,
                        isLoading: false
                    })
                } else {
                    this.closeModalUpload();

                    this.setState({
                        oncall: true,
                        mstatus: response.data.data[0].status,
                        isLoading: false
                    })

                    // alert(response.data.data[0].status)
                }

            })

            // } else {
            //     this.setState({ statusMessage: response.data.message, isLoading4: false, showError: true, isShowError: false, });
            // }
        }).catch((error) => {
            this.setState({ isLoading4: false });

            alert(JSON.stringify(error.response))
        })
    }



    handleChange = value => {
        if (value != null) {

            this.setState(
                {
                    // ...this.state.editedInvoice,
                    call_status: value.value.toString(),
                    call_value: [
                        { value: value.value.toString(), label: value.value.toString() }],

                    // customer_name: value.label.toString(),
                    // customer_nameinvoice2: value.customer_nameinvoice.toString(),
                    // customer_email: value.customer_email.toString(),
                });
        }
    };

    handleChangeMessage = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }


    handleChangeRemarks = value => {
        if (value != null) {

            this.setState(
                {
                    // ...this.state.editedInvoice,
                    remarks: value.value.toString(),
                    remark_value: [

                        { value: value.value.toString(), label: value.value.toString() }],

                    // customer_name: value.label.toString(),
                    // customer_nameinvoice2: value.customer_nameinvoice.toString(),
                    // customer_email: value.customer_email.toString(),
                });
        }
    };

    handleChangeCallType = value => {
        if (value != null) {

            this.setState(
                {
                    // ...this.state.editedInvoice,
                    call_type: value.value.toString(),
                    call_type_value: [

                        { value: value.value.toString(), label: value.value.toString() }],

                    // customer_name: value.label.toString(),
                    // customer_nameinvoice2: value.customer_nameinvoice.toString(),
                    // customer_email: value.customer_email.toString(),
                });
        }
    };


    render() {
        // console.log("Load", isLoading);
        return (
            <div>
                <>
                    <Col md={12}>
                        <h4>User Details</h4><br />

                    </Col>
                    < Col md={12} lg={12} >
                        < Card >
                            <CardBody >
                                <div className="panel-body" >
                                    <Link to="/user">
                                        <Button className="pull-right"
                                            color="primary" outline>Go Back</Button><br /> <br /><br />
                                    </Link>
                                    {this.state.isShowError ?
                                        <div className="alert alert-success" > 
                                              {this.state.statusMessage}
                                        </div> : null
                                    }
                                    <div className="row">
                                        <Modal
                                            isOpen={this.state.isOpen}
                                            onRequestClose={e => {
                                                this.closeModalUpload(e);
                                            }}
                                            contentLabel="My dialog"
                                            className="mymodalclient"
                                            onAfterOpen={() => {
                                                document.body.style.overflow = 'hidden';
                                            }}
                                            onAfterClose={() => {
                                                document.body.removeAttribute('style');
                                            }}
                                            overlayClassName="myoverlay"
                                            closeTimeoutMS={500}
                                        >


                                            <h4><b>Add Remark </b></h4>
                                            <br />
                                            <label>Call Type</label>
                                            <Select
                                                isClearable
                                                options={options_3}
                                                // onInputChange={this.loadOptions}
                                                placeholder="Call type"
                                                autosize={true}
                                                onChange={this.handleChangeCallType}
                                                value={this.state.call_type_value}
                                                isLoading={this.state.isLoading}
                                                menuPortalTarget={document.body}
                                                name="name"
                                            />
                                            <br />
                                            <label>Call Status</label>
                                            <Select
                                                isClearable
                                                options={options}
                                                // onInputChange={this.loadOptions}
                                                placeholder="Call Status"
                                                autosize={true}
                                                onChange={this.handleChange}
                                                value={this.state.call_value}
                                                menuPortalTarget={document.body}
                                                name="name"
                                            />

                                            <br />

                                            <label>Remark</label>


                                            <Select
                                                isClearable
                                                options={options_2}
                                                // onInputChange={this.loadOptions}
                                                placeholder="Remarks"
                                                autosize={true}
                                                onChange={this.handleChangeRemarks}
                                                value={this.state.remark_value}
                                                isLoading={this.state.isLoading}
                                                menuPortalTarget={document.body}
                                                name="name"
                                            />
                                            <br />

                                            <div className="form-group">
                                                <textarea name="message" style={{ paddingTop: '20px' }} onChange={this.handleChangeMessage}
                                                    className="col-md-12" placeholder="Call summary"
                                                    id="message" cols="col-md-1" rows="3"></textarea>
                                            </div><br />
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <button
                                                        onClick={this.onSubmitRemarkUpdate}
                                                        className="btn btn-primary btn-sm"
                                                    >
                                                        {this.state.isLoading4 ? "Sending" : "Submit"}


                                                    </button>
                                                </div>
                                            </div>
                                        </Modal>

                                        <div className="col-md-4">
                                            <div className="col-md-8 offset-md-2">

                                                <Link to="/game">
                                                    <Button className=""
                                                        color="primary" outline>Bets: Games </Button><br /> <br /><br />
                                                </Link>
                                                <Link to="/statement">
                                                    <Button className=""
                                                        color="primary" outline>Finance: Statements</Button><br /> <br /><br />
                                                </Link>

                                                <h3 className=""
                                                    color="primary" >Messages</h3><br /> <br />
                                                <Link to="/incoming">
                                                    <Button className=""
                                                        color="primary" outline> Incoming Messages </Button><br /> <br /><br />
                                                </Link>
                                                <Link to="/outgoing">
                                                    <Button className=""
                                                        color="primary" outline> Outgoing Messages </Button><br /> <br /><br />
                                                </Link>
                                                <Link to="/allmessages">
                                                    <Button className=""
                                                        color="primary" outline> All Messages </Button><br /> <br /><br />
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <h3>More about {localStorage.getItem("phone")}</h3><br />

                                            {this.state.oncall === false ?
                                                <div className="col-4">
                                                    <a href="#" style={{ fontSize: "16px" }} onClick={() => { this.onSubmitRemark() }}>
                                                        {this.state.isLoading4 ? "Sending" : "Are you on Call?"}</a>
                                                </div>
                                                :
                                                <div className="col-4">
                                                    <h4><b>{this.state.mstatus}</b></h4> :
                                                    <br />
                                                    {/* 
                                                <a href="#" style={{ fontSize: "16px" }} onClick={() => { this.toggleModalFloat() }}>{this.state.isLoading1 ? "Sending Please Wait..." : "On Call?"}</a> */}
                                                </div>
                                            }

                                            < ReactDatatable config={this.config}
                                                records={this.state.data}
                                                id="tsc"
                                                columns={this.columns}
                                                loading={this.state.isLoading}
                                                extraButtons={this.extraButtons}
                                            />


                                            < ReactDatatable config={this.config}
                                                records={this.state.data}
                                                id="tsc"
                                                columns={this.columns3}
                                                loading={this.state.isLoading}
                                                extraButtons={this.extraButtons}
                                            />

                                        </div></div>
                                </div>

                            </CardBody>

                        </Card>
                    </Col>
                </>
            </div>

        )
    }
}