import * as React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { baseURL } from '../../_helpers';
import { Card, CardBody, Col, Button } from 'reactstrap';
import $ from 'jquery'
import Select from "react-select";
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'
import * as moment from 'moment';
window.$ = window.jQuery = require('jquery')
// require("http://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js")
require('../../_assets/jss/sms_counter.js')

export class BlastCountPlayed extends React.Component {

    constructor(props) {
        super(props)
        this.applyCallback = this.applyCallback.bind(this);
        this.state = {
            showComponent: false,
            hideComponent: true,
            selected: [],
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.handleMultiChange = this.handleMultiChange.bind(this);
        this.onSMSGetCount = this.onSMSGetCount.bind(this);

        this.onSubmit = this.onSubmit.bind(this);
        let now = new Date();
        let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
        let end = moment(start).add(1, "days").subtract(1, "seconds");

        this.state = {
            alert_color: '',
            isLoading: false,
            isShowError: false,
            name: '',
            succesStatus: false,
            statusMessage: '',
            start: start,
            end: end,
            message: "",

            multiValue: [],
            startDate: moment(new Date()).format('YYYY-MM-DD') + " 00:00:00",
            endDate: moment(new Date()).format('YYYY-MM-DD') + " 23:59:59",
            valuedate: "Today",
            playcounts: 0,
            showError: false,
            terms3: [
                {
                    label: 'Once',
                    value: 1,
                },
                {
                    label: 'Twice',
                    value: 2,
                },
                {
                    label: 'Thrice',
                    value: 3,
                },
                {
                    label: 'Above Fourth time',
                    value: 4,
                }
            ],
            terms_channel: [
                {
                    label: 'Infobip',
                    value: 'Infobip',
                },
                {
                    label: 'SDP',
                    value: "SDP"
                }
            ]
        }
    }
    componentDidMount() {
        $('#message').countSms('#sms-counter');
    }

    onSubmit(e) {
        e.preventDefault();


        let formData = {
            // "days": this.state.days,
            // "last_days": this.state.last_days,
            "startDate": this.state.startDate,
            "endDate": this.state.endDate,
            "total_limit": this.state.total_limit,
            "playcounts": this.state.playcounts,
            "message": this.state.message.replaceAll("’", "'"),
        }
        this.setState({ isLoading: true, succesStatus: true });
        axios.post(baseURL + 'sendbasedonplaycount', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        }).then((response) => {
            // console.log('bayoo', JSON.stringify(response.data))
            if (response.data.status) {
                this.setState({ statusMessage: " Bulk sms send Successfully", isShowError: true, succesStatus: false, alert_color: "alert alert-success", isLoading: false });
                localStorage.setItem("balstmessage", response.data.totalsms)
                localStorage.setItem("submessage", response.data.subscriptions)

                window.setTimeout(function () {
                    window.location.href = "/bulksmsmessage";
                }, 2000);
            } else {

                this.setState({ statusMessage: "Something went wrong", alert_color: "alert alert-danger", succesStatus: false, isShowError: true, isLoading: false });
            }

        }, error => {
            this.setState({ statusMessage: "error", isShowError: true, succesStatus: false, alert_color: "alert alert-danger", isLoading: false });
        });
    }


    onSMSGetCount(e) {
        e.preventDefault();
        this.setState({ isLoading: true, succesStatus: false });

        axios.get(baseURL + 'smscount?startDate=' + this.state.startDate + "&endDate=" + this.state.endDate, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        }).then((response) => {
            this.setState({ isLoading: false, succesStatus: false });

            if (window.confirm('Total counts: ' + JSON.stringify(response.data.total))) {
                this.onSubmit()
            }
            // this.onSubmitblacklist(record)

        }, error => {
            this.setState({ statusMessage: "error", isShowError: true, succesStatus: false, alert_color: "alert alert-danger", isLoading: false });
        });
    }


    Constituency() {
        // eslint-disable-next-line
        return (this.state.type && (this.state.type.length == 0 || this.state.type.length > 0) &&
            this.state.type.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.id })))
    }
    handleMultiChange = value => {
        if (value != null) {
            this.setState(
                {
                    playcounts: value.value,
                });
        }
        else {
            this.setState(
                {
                    playcounts: "",
                });

        }
    }

    handleChangeChannel = value => {
        if (value != null) {
            this.setState(
                {
                    channel: value.value,
                });
        }
        else {
            this.setState(
                {
                    channel: "",
                });

        }
    }
    handleChangeType = value => {
        this.setState(state => {
            return {
                // playcounts: value
            };
        });
        // if (value != null) {
        //     this.setState(
        //         {
        //             terms: value.value.toString(),
        //         });
        // }
        // else {
        //     this.setState(
        //         {
        //             terms: "",
        //         });
        // }
    };
    onSelectChangeConstitueny = value => {
        this.setState({ constituency_id: value.value.toString() });
    };

    onSelectChange = value => {

        this.setState({ entity_id: value.value.toString() });
    };


    handleChangeGender = (event) => {
        this.setState({ selectedGender: event.target.value });
        //alert(event.target.value)
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    _onButtonClick() { }
    changeStatus() {
        this.setState({
            isShowError: false
        });
    }

    changeList(setSelected) {
        this.setState({ setSelected });
    }

    applyCallback(startDate, endDate) {
        let Sdate1 = moment(startDate).format('YYYY-MM-DD');
        let Edate2 = moment(endDate).format('YYYY-MM-DD');
        this.setState({
            valuedate: Sdate1 + " " + Edate2,
            startDate: Sdate1 + " 00:00:00",
            isPageLoad: true,
            endDate: Edate2 + " 23:59:59"
        })

        // this.getData2(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
    }


    render() {
        const { hideComponent, succesStatus } = this.state;

        let ranges = {
            "Today Only": [moment(this.state.start), moment(this.state.end)],
            "Yesterday Only": [
                moment(this.state.start).subtract(1, "days"),
                moment(this.state.end).subtract(1, "days")
            ],
            "3 Days": [moment(this.state.start).subtract(3, "days"), moment(this.state.end)],
            "5 Days": [moment(this.state.start).subtract(5, "days"), moment(this.state.end)],
            "1 Week": [moment(this.state.start).subtract(7, "days"), moment(this.state.end)],
            "2 Weeks": [moment(this.state.start).subtract(14, "days"), moment(this.state.end)],
            "1 Month": [moment(this.state.start).subtract(1, "months"), moment(this.state.end)],
            "1 Year": [moment(this.state.start).subtract(1, "years"), moment(this.state.end)]
        };
        let local = {
            "format": "DD-MM-YYYY",
            "sundayFirst": false
        }


        return (
            <div className="pcoded-main-container">


                {succesStatus == true ?
                    <h4>Sending...... please be patient</h4>
                    :
                    <>
                        {!hideComponent && (
                            <>

                                <Col className="col-md-10 offset-md-1">


                                    <Card>
                                        <CardBody><br />
                                            <form onSubmit={this.onSubmit}>

                                                <h4 className="form-label">Blast based on playing counts</h4><br />
                                                <br />
                                                 <br />

                                                <div className="col-md-8">

                                                    <Select
                                                        isClearable
                                                        options={this.state.terms3}
                                                        // onInputChange={this.loadOptions}
                                                        placeholder="Count of plays"
                                                        autosize={true}
                                                        onChange={this.handleMultiChange}
                                                        // isLoading={this.state.isLoading}
                                                        // noOptionsMessage={() => 'nothing found'}
                                                        // loadingMessage={() => 'searching...'}   //minor type-O here
                                                        className="selected"
                                                        menuPortalTarget={document.body}
                                                        name="namffe"
                                                    /><br />
                                                </div>
                                                <br />
                                                <div className="col-md-8">
                                                    <DateTimeRangeContainer
                                                        ranges={ranges}
                                                        start={this.state.start}
                                                        end={this.state.end}
                                                        local={local}
                                                        applyCallback={this.applyCallback}>
                                                        <FormControl
                                                            id="formControlsTextB"
                                                            type="text"
                                                            value={this.state.valuedate}
                                                            label="Text"
                                                            placeholder="Filter by Date"
                                                        />
                                                    </DateTimeRangeContainer>

                                                </div>


                                                {/* <h5><b>BETWEEN</b></h5> <br />

                                                <div className="form-group">
                                                    <input id="input" type="number" className="form-control input-md"
                                                        name="days" maxLength="1000" required placeholder="Enter Days"
                                                        value={this.state.days} onChange={this.handleChange} />
                                                </div>

                                                <h4><b>AND</b></h4><br />

                                                <div className="form-group">
                                                    <input id="input" type="number" className="form-control input-md"
                                                        name="last_days" maxLength="1000" required placeholder="Enter Days"
                                                        value={this.state.last_days} onChange={this.handleChange} />
                                                </div> */}


                                                <br />
                                                <h4>Create Message</h4>
                                                {this.state.isShowError ? <div className={this.state.alert_color}
                                                    style={{ fontSize: '15px' }}>
                                                    {this.state.statusMessage}</div> : null}
                                                <br></br>

                                                <label className="form-label">Limit</label>
                                                <div className="form-group">
                                                    <input id="input" type="number" className="form-control input-md"
                                                        name="total_limit" required placeholder="Enter Limit"
                                                        value={this.state.total_limit} onChange={this.handleChange} />
                                                </div>

                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-8" >
                                                            <textarea name="message" style={{ paddingTop: '20px' }} onChange={this.handleChange}
                                                                className="col-md-12" placeholder="type your message here"
                                                                id="message" cols="col-md-1" rows="20"></textarea>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <ul id="sms-counter">
                                                                <li>Length: <span class="length"></span></li>
                                                                <li>SMS: <span class="messages"></span></li>
                                                                <li>Per Message: <span class="per_message"></span></li>
                                                                <li>Remaining: <span class="remaining"></span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div><br />
                                                <div className="col-md-12">
                                                    <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right
                                                        " >
                                                        {this.state.isLoading ? "Please Wait..." : "Send "}
                                                        <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;
                                                </div>
                                            </form>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </>

                        )
                        }
                    </>
                }

            </div>
        )

    }


}
