export * from './storage.helper';
export * from "./history";
export * from "./isLoggedIn";
export * from "./baseURL";
//currency
export const formatCurrency = amount => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: "ABS",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount).replaceAll('ABS', "");
};

// header configurations
export const CONFIG = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': localStorage.getItem('token')
    },
};
