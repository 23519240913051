import React from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import * as moment from 'moment';

export class Customers extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "phone",
                TrOnlyClassName: 'tsc',
                text: "Phone Numnber",
                className: "tsc",
                align: "left"
            },
            {
                key: "mail",
                TrOnlyClassName: 'tsc',
                text: "Email",
                className: "tsc",
                align: "left"
            },
            {
                key: "registrationChannel",
                TrOnlyClassName: 'tsc',
                text: "Registration Channel",
                className: "tsc",
                align: "left"
            },
            {
                key: "mbalance",
                TrOnlyClassName: 'tsc',
                text: "Balance",
                className: "tsc",
                align: "left"
            },
            {
                key: "dates",
                TrOnlyClassName: 'tsc',
                text: "Datecreated",
                className: "tsc",
                align: "left"
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Enter Phone/Email...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }

        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "") => {
        this.setState({
            isLoading: true,
        })
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + "customers?" + queryString
        console.log("query", url);
        const config = {
            headers: {
                'Authorization': TOKEN
            }
        };
        console.log("token", TOKEN)
        axios.all([
            axios.get(url, axiosConfig),
            axios.get(baseURL + "count", config),
            axios.get(baseURL + "balance", config),
        ]).then(axios.spread((branchResponse, entityResponse, balanceResponse) => {
            this.setState({
                admins: branchResponse.data,
                mdata: entityResponse.data.customer,
                mbalance: balanceResponse.data.balance,
                isLoading: false,
            },
                function () {
                    var data = [];
                    console.log("BUG", this.state.admins)

                    if (this.state.admins.length > 0) {
                        for (let i = 0; i < this.state.admins.length; i++) {
                            let mail;
                            let mbalance;
                            let  phone;
                            if (this.state.admins[i].email == null) {
                                mail = { mail: "_" };
                            } else {
                                mail = { mail: this.state.admins[i].email };
                            }

                            if (this.state.admins[i].balance === 0) {
                                mbalance = { mbalance: "0.0" };
                            } else {
                                mbalance = { mbalance: this.state.admins[i].balance };
                            }

                            if (this.state.admins[i].msisdn === null) {
                                phone = { phone: "_" };
                            } else {
                                phone = { phone: this.state.admins[i].msisdn };
                            }

                            let date = { dates: moment.utc(this.state.admins[i].time).format('DD, MMM YYYY HH:mm:ss') };
                            data.push(Object.assign(date, mail, phone, mbalance, this.state.admins[i]));
                            this.setState({
                                data: data
                            })
                            console.log("bugs", data);
                        }
                    } else {
                        this.setState({
                            data: data,
                            isLoading: false,

                        })
                        console.log("bugs", data);
                    }
                }
            );
        }))
    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    render() {
        return (
            <div >
                < >
                    <Col md={12}>
                        <h3>Customers</h3><br />

                    </Col>
                    < Col md={12} lg={12} >
                        < Card >
                            <CardBody >
                                <div className="panel-body" >

                                    <div >
                                        Customers as at Now: <b>{this.state.mdata}</b> || Total Ewallet: <b>{this.state.mbalance}</b>
                                        <br /><br /><br />
                                    </div>
                                    {this.state.isShowError ?
                                        <div className="alert alert-success" > {this.state.statusMessage}
                                        </div> : null
                                    }
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.data}
                                        columns={this.columns}
                                        dynamic={true}
                                        id="tsc"
                                        loading={this.state.isLoading}
                                        total_record={this.state.mdata}
                                        onChange={this.tableChangeHandler} />
                                </div>
                            </CardBody>

                        </Card>
                    </Col>
                </>
            </div>
        )
    }
}