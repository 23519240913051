import * as React from 'react';
import '../../_assets/css/file.css';
import axios from 'axios';
import { baseURL } from '../../_helpers';
import { Card, CardBody, Col, Button } from 'reactstrap';
import $ from 'jquery'
import DatePicker from 'react-datepicker';
import Select from "react-select";
import * as moment from 'moment';

window.$ = window.jQuery = require('jquery')
// require("http://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js")
require('../../_assets/jss/sms_counter.js')

export class sendSMS extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            showComponent: false,
            hideComponent: true,
            selected: [],
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.handleMultiChange = this.handleMultiChange.bind(this);

        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            alert_color: '',
            isLoading: false,
            isShowError: false,
            name: '',
            succesStatus: false,
            statusMessage: '',
            multiValue: [],
            payment_date: new Date(),
            showError: false,
            terms3: [
                {
                    label: 'Almost Out',
                    value: 'Almost Out',
                },
                {
                    label: 'First Grade',
                    value: 'First Grade',
                },
                {
                    label: 'Loyal',
                    value: 'Loyal',
                },
                {
                    label: 'Likely to be Loyal',
                    value: 'Likely to be Loyal',
                },
                {
                    label: 'Require Assistance',
                    value: 'Require Assistance',
                },
                {
                    label: 'Getting Less Frequent',
                    value: 'Getting Less Frequent',
                },
                {
                    label: "Don't Show Up at All",
                    value: "Don't Show Up at All",
                }
            ]
        }
    }
    componentDidMount() {
        $('#message').countSms('#sms-counter');
    }



    onSubmit(e) {
        e.preventDefault();

        var sha512 = require('js-sha512');

        let signature = sha512(this.state.message + 'P@s5w0rd_5@lt' + moment(this.state.payment_date).format('YYYY-MM-DD HH:mm:ss'))
        let formData = {
            "dispatch_time": moment(this.state.payment_date).format('YYYY-MM-DD HH:mm:ss'),
            "message": this.state.message
        }

        // alert(JSON.stringify(formData))
        this.setState({ isLoading: true, succesStatus: true });
        axios.post('https://luckybox.ng:3886/schedule_blast', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'signature': signature
            },
        }).then((response) => {
            console.log('bayoo', response.status)
            if (response.data.code === "0000") {
                this.setState({ statusMessage: response.data.message, isShowError: true, succesStatus: false, alert_color: "alert alert-success", isLoading: false });
                localStorage.setItem("balstmessage", response.data.totalsms)
                window.setTimeout(function () {
                    window.location.href = "/scheduled_message";
                }, 2000);
            } else {

                this.setState({ statusMessage: response.data.message, alert_color: "alert alert-danger", succesStatus: false, isShowError: true, isLoading: false });
            }

        }, error => {
            this.setState({ statusMessage: error.response.data.message, isShowError: true, alert_color: "alert alert-danger",
             succesStatus: false, isLoading: false });
        });
    }

    Constituency() {
        // eslint-disable-next-line
        return (this.state.type && (this.state.type.length == 0 || this.state.type.length > 0) &&
            this.state.type.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.id })))
    }
    handleMultiChange(option) {

        // alert(JSON.stringify(option))
        this.setState(state => {
            return {
                multiValue: option
            };
        });
    }
    handleChangeType = value => {
        this.setState(state => {
            return {
                multiValue: value
            };
        });
        // if (value != null) {
        //     this.setState(
        //         {
        //             terms: value.value.toString(),
        //         });
        // }
        // else {
        //     this.setState(
        //         {
        //             terms: "",
        //         });
        // }
    };
    onSelectChangeConstitueny = value => {
        this.setState({ constituency_id: value.value.toString() });
    };

    onSelectChange = value => {

        this.setState({ entity_id: value.value.toString() });
    };


    handleChangeGender = (event) => {
        this.setState({ selectedGender: event.target.value });
        //alert(event.target.value)
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    _onButtonClick() { }
    changeStatus() {
        this.setState({
            isShowError: false
        });
    }

    changeList(setSelected) {
        this.setState({ setSelected });
    }
    render() {
        const { hideComponent, succesStatus } = this.state;

        return (
            <div className="pcoded-main-container">


                { succesStatus == true ?
                    <h4>Sending...... please be patient</h4>
                    :
                    <>
                        {!hideComponent && (
                            <>
                                <Col className="col-md-10 offset-md-1">
                                    <Card>
                                        <CardBody><br />
                                            <form onSubmit={this.onSubmit}>
                                                {/* <Select
                                            isClearable
                                            isMulti
                                            options={this.state.terms3}
                                            // onInputChange={this.loadOptions}
                                            placeholder="Select Segment"
                                            autosize={true}
                                            onChange={this.handleMultiChange}
                                            // isLoading={this.state.isLoading}
                                            // noOptionsMessage={() => 'nothing found'}
                                            // loadingMessage={() => 'searching...'}   //minor type-O here
                                            className="selected"
                                            menuPortalTarget={document.body}
                                            name="namffe"
                                        /><br /> */}
                                                <div className="form-group">
                                                    <label className="form-label">Set Dispatch Time</label><br />
                                                    <DatePicker
                                                        selected={this.state.payment_date}
                                                        label="DatePicker Label"
                                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                                        required
                                                        showTimeSelect={true}
                                                        timeIntervals={15}
                                                        placeholder="enter date"
                                                        value={this.state.payment_date}
                                                        className="form-control"
                                                        id="input"
                                                        onChange={value =>
                                                            this.setState({ payment_date: value })}
                                                    />

                                                </div>
                                                <br />
                                                <h4>Create Message</h4>
                                                {this.state.isShowError ? <div className={this.state.alert_color}
                                                    style={{ fontSize: '15px' }}>
                                                    {this.state.statusMessage}</div> : null}
                                                <br></br>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-8" >
                                                            <textarea name="message" style={{ paddingTop: '20px' }} onChange={this.handleChange}
                                                                className="col-md-12" placeholder="type your message here"
                                                                id="message" cols="col-md-1" rows="20"></textarea>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <ul id="sms-counter">
                                                                <li>Length: <span class="length"></span></li>
                                                                <li>SMS: <span class="messages"></span></li>
                                                                <li>Per Message: <span class="per_message"></span></li>
                                                                <li>Remaining: <span class="remaining"></span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div><br />
                                                <div className="col-md-12">
                                                    <Button type="submit" color="primary" outline className="btn-paypal btn-lg pull-right
                                                        " >
                                                        {this.state.isLoading ? "Please Wait..." : "Send "}  <i className="fa fa-refresh"></i></Button> &nbsp;&nbsp;&nbsp;
                                       </div>
                                            </form>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </>

                        )
                        }
                    </>
                }

            </div>
        )

    }


}
